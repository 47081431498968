import { router } from '@/router';
import i18n from '@/locales/index';
import MyDialog from '@/plugins/component/MyDialog';
import MyToast from '@/plugins/component/MyToast';
import useClipboard from 'vue-clipboard3';
import { useUserStore, usePopupStore, useAccountStore } from '@/store';
import { shareSocialMedia, followSocialMedia, enterGame } from "@/api";

const { toClipboard } = useClipboard();

export const useHelpTool = () => {
  const showLoginDialog = () => {
    MyDialog.confirm({
      message: i18n.global.t('notify.loginFirst'),
    })
    .then(() => {// on confirm
      router.push({ name: 'login' })
    })
    .catch(() => {});
  }

  const checkUserLogin = (callback) => {
    if (!useUserStore().uid) {
      showLoginDialog();
    } else {
      callback && callback();
    }
  }

  const doShare = (postData) => {
    checkUserLogin(()=> {
      shareSocialMedia(postData).then(res => {
        let url = res.data;
        if (window.$bridge) {
          window.$bridge.callHandler('share', JSON.stringify({
            method: 'app',
            url,
            type: 'url'
          }))
        } else {
          MyDialog.alert({ 
            message: i18n.global.t('notify.shareSuccess') 
          }).then(() => {// on confirm
            copy(url, false);
          });
        }
      }) 
    })
  }

  const doFollow = (postData) => {
    followSocialMedia(postData).then(res => {
      let url = res.data;
      openBrowser(url, { showAlert: true });
    })
  }

  const doEnterGame = (postData) => {
    checkUserLogin(()=> {
      enterGame(postData).then(res => {
        let { playUrl, openMode, needBindWallet } = res.data || {};
        if (needBindWallet) {
          usePopupStore().openBindWallet();
        } else {
          if (window.$bridge && openMode === 'browser') {
            window.$bridge.callHandler('openBrowser', playUrl);
          } else {
            usePopupStore().openGameFrame(playUrl, () =>{
              useAccountStore().fetchBalance();
            });
          }
        }
      });
    })
  }

  const copy = async(text, showToast = true) => {
    try {
      await toClipboard(text);
      if(showToast) {
        MyToast(i18n.global.t('notify.copySuccess'));
      }
    } catch (e) {
      console.error(e)
    }
  }

  const openUrl = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openUrl', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openBrowser = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openBrowser', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openUrlInWeb = (url, options = {}) => {
    if (options.showAlert || options.message) {
      MyDialog.alert({
        message: options.message ? options.message : i18n.global.t('msg.openBrowser'),
        confirmButtonText: i18n.global.t('common.go').toUpperCase(),
      })
      .then(() => {// on close
        let a = document.createElement('a');
        a.setAttribute('target','_blank');
        a.setAttribute('href', url);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } else {
      let a = document.createElement('a');
      a.setAttribute('target','_blank');
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  // const imageByTheme = (image) => {
  //   return require(`@/assets/img/theme/${useSettingsStore().theme}/${image}`);
  // }

  // const backgroundByTheme = (image) => {
  //   return 'background-image: url(' + imageByTheme(image) + ');';
  // }

  return { 
    showLoginDialog, 
    checkUserLogin, 
    doShare, 
    doFollow, 
    doEnterGame, 
    copy,
    openUrl, 
    openBrowser, 
    // imageByTheme, 
    // backgroundByTheme
  }
}
