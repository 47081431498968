import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

import { ref, watch, provide, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useEventListener } from '@vant/use';
import { Local } from '@/locales/index';
import { storage } from '@/utils/storage'
import ws from '@/connect';
import { usePageVisibility } from '@vant/use';
import { useAudio } from "@/hooks";
import { useSettingsStore, useUserStore, useAccountStore, usePopupStore, useReddotStore, useGameStore } from '@/store'
import Reward from '@/components/Reward.vue';
// import Follow from './components/Follow.vue';
import GameFrame from '@/components/GameFrame';
import BindWallet from '@/components/BindWallet';
import MyToast from '@/plugins/component/MyToast';
import MyDialog from '@/plugins/component/MyDialog';


export default {
  __name: 'App',
  setup(__props) {

const themeVars = {
  fieldPlaceholderTextColor: '#C1C1D6',
  buttonDisabledOpacity: '1',
};

const { playAudio } = useAudio();
const pageVisibility = usePageVisibility();

const settingsStore = useSettingsStore()
const userStore = useUserStore()
const accountStore = useAccountStore(); 
const gameStore = useGameStore();
const popupStore = usePopupStore();
const { showReward, showFollow, showGameFrame, showBindWallet } = storeToRefs(popupStore);
const redDotStore = useReddotStore();

watch(pageVisibility, (newValue, oldValue) => {
  if (newValue === 'visible') {
    userStore.loadUserInfo(() => {
      if (!showGameFrame.value) {
        accountStore.fetchBalance();
      }
    }, () => {
      redDotStore.$reset();
    });
  }
})

onMounted(() => {
  let lang = navigator.browserLanguage || navigator.language;
  let langList = ['en-US', 'vi-VN']
  if (langList.indexOf(lang) < 0) {
    lang = langList[0]
  }
  Local(lang);

  settingsStore.initDeviceInfo();
  settingsStore.fetchConfig();
  settingsStore.checkAppVersion();
  settingsStore.setTheme(storage.get('theme', 'light'));
  
  gameStore.fetchMenu();

  userStore.loadUserInfo(() => {
    init();
  });
})

const init = () => {
  accountStore.fetchBalance();
  redDotStore.fetchData(); // 获取红点数量
  ws.connect();
  // 注册websocket回调方法
  ws.registerCallBack('inbox.unread', (data)=> {
    redDotStore.set('inboxUnread', data)
  })
  ws.registerCallBack('invite.friends.spins', (data)=> {
    redDotStore.set('inviteFriendToEarnNum', data)
  })
  ws.registerCallBack('invite.friends.energy', (data)=> {
    redDotStore.set('inviteFriendEnergy', data)
  })
  ws.registerCallBack('task.reward', (data)=> {
    redDotStore.set('taskRewardNum', data)
  })
  ws.registerCallBack('badge.sign.up', (data)=> {
    redDotStore.set('loginBonusRewardNum', data)
  })
  ws.registerCallBack('daily.deposit.sign', (data)=> {
    redDotStore.set('dailyDepositSign', data)
  })
  ws.registerCallBack('catch.doll', (data)=> {
    redDotStore.set('catchDollCount', data)
  })
  ws.registerCallBack('catch.doll.level', (data)=> {
    redDotStore.set('catchDollLevel', data)
  })
}

provide('myToast', MyToast);
provide('myDialog', MyDialog);
provide('userHelper', { init });

// 自动播放背景音乐
useEventListener(
  'click',
  () => {
    playAudio('bgm');
  },
  { target: document.body },
);

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")
  const _component_van_config_provider = _resolveComponent("van-config-provider")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_config_provider, { "theme-vars": themeVars }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view),
        (_unref(showReward))
          ? (_openBlock(), _createBlock(Reward, {
              key: 0,
              onOnClose: _cache[0] || (_cache[0] = $event => (_unref(popupStore).set('showReward',false)))
            }))
          : _createCommentVNode("", true),
        (_unref(showGameFrame))
          ? (_openBlock(), _createBlock(_unref(GameFrame), {
              key: 1,
              onOnClose: _cache[1] || (_cache[1] = $event => (_unref(popupStore).closeGameFrame()))
            }))
          : _createCommentVNode("", true),
        (_unref(showBindWallet))
          ? (_openBlock(), _createBlock(_unref(BindWallet), {
              key: 2,
              onOnClose: _cache[2] || (_cache[2] = $event => (_unref(popupStore).set('showBindWallet', false)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("audio", {
      id: "bgm",
      src: require('@/assets/audio/bgm.mp3'),
      loop: ""
    }, null, 8, _hoisted_1),
    _createElementVNode("audio", {
      id: "clickSound",
      src: require('@/assets/audio/click-btn.wav')
    }, null, 8, _hoisted_2),
    _createElementVNode("audio", {
      id: "closeSound",
      src: require('@/assets/audio/close.wav')
    }, null, 8, _hoisted_3),
    _createElementVNode("audio", {
      id: "openSound",
      src: require('@/assets/audio/open-treasure.mp3')
    }, null, 8, _hoisted_4),
    _createElementVNode("audio", {
      id: "rollSound",
      src: require('@/assets/audio/roll-wheel.wav')
    }, null, 8, _hoisted_5)
  ], 64))
}
}

}